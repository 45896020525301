import { isNonEmptyArray } from '@getvim-os/types';
import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { useCallback, useEffect, useState } from 'react';
import { EhrUpdatableEntities, UpdateEncounter, UpdateEncounterInput } from '../../types/ehr';

export const useEhrUpdatableEntities = (): EhrUpdatableEntities => {
  const { resourceUpdater } = useApi();
  const [diagnoses, setDiagnoses] = useState<boolean>(
    resourceUpdater.canUpdateEncounter({
      assessment: { diagnosisCodes: true },
    }).canUpdate,
  );
  const [procedures, setProcedures] = useState<boolean>(
    resourceUpdater.canUpdateEncounter({
      billingInformation: { procedureCodes: true },
    }).canUpdate,
  );

  useEffect(() => {
    const cb = () => {
      setDiagnoses(
        resourceUpdater.canUpdateEncounter({
          assessment: { diagnosisCodes: true },
        }).canUpdate,
      );

      setProcedures(
        resourceUpdater.canUpdateEncounter({
          billingInformation: { procedureCodes: true },
        }).canUpdate,
      );
    };
    resourceUpdater.subscribe('encounter', cb);
    return () => {
      resourceUpdater.unsubscribe('encounter', cb);
    };
  }, [resourceUpdater]);

  return {
    encounter: {
      diagnoses,
      procedures,
    },
  };
};

export const useWriteback = (): UpdateEncounter => {
  const { resourceUpdate } = useApi();

  return useCallback(
    async (input: UpdateEncounterInput) => {
      const { addedDiagnoses, addedProcedures } = input;
      if (isNonEmptyArray(addedDiagnoses)) {
        return await resourceUpdate
          .encounterBuilder()
          .updateAssessment({ diagnosisCodes: addedDiagnoses })
          .commit();
      } else if (isNonEmptyArray(addedProcedures)) {
        return await resourceUpdate
          .encounterBuilder()
          .updatePlan({ procedureCodes: addedProcedures })
          .commit();
      }
    },
    [resourceUpdate],
  );
};
